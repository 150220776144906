import { createContext, useContext, useState } from "react"
import { Type } from "../../reactor/Types/Type"

export type SelectionContext = {
    /** The object that is selected for inspection */
    selectedObject: any
    selectedObjectType?: Type
    /** The type supported by the PUT operation, if different from the GET operation.
     * Only the common set of properties between the GET and PUT operation will be editable. */
    selectedObjectPutType?: Type
    setSelectedObject(obj: any, type: Type, putType?: Type): void
    clearSelectedObject(): void
}

export const DummySelectionContext: SelectionContext = {
    /** The object that is selected for inspection */
    selectedObject: null,
    setSelectedObject(obj: any, type: Type) {},
    clearSelectedObject() {},
}

export const SelectionContext = createContext<SelectionContext | null>(null)

export function useSelectionContext() {
    const context = useContext(SelectionContext)
    if (!context) throw new Error("No selection context")
    return context
}

export function SelectionContextProvider({ children }: { children: React.ReactNode }) {
    const [_selectedObject, _setSelectedObject] = useState<
        { obj: any; type: Type; putType?: Type } | undefined
    >(undefined)

    return (
        <SelectionContext.Provider
            value={{
                selectedObject: _selectedObject?.obj,
                selectedObjectType: _selectedObject?.type,
                selectedObjectPutType: _selectedObject?.putType,
                setSelectedObject(obj: any, type: Type, putType?: Type) {
                    _setSelectedObject({ obj, type, putType })
                },
                clearSelectedObject() {
                    _setSelectedObject(undefined)
                },
            }}
        >
            {children}
        </SelectionContext.Provider>
    )
}
