import { GetBaysDto } from "../client"

export function Landing({ bays }: { bays?: GetBaysDto[] }) {
    return (
        <table>
            <thead>
                <tr>
                    <th>Bay</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {bays?.map((bay) => (
                    <tr key={bay.id.valueOf()}>
                        <td>
                            <a href={`/sites/${bay.siteId}`} style={{ marginRight: "15px" }}>
                                {bay.name}
                            </a>
                        </td>
                        <td>{bay.operationMode}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
