import { Route, Routes } from "react-router-dom"
import { LocalesProvider } from "../../../packages/localization/client-side/useLocalesContext"
import { ModalProvider } from "../../../packages/modal/Modal"
import { WebError } from "../../../packages/web/components/WebError"
import { Studio } from "../../../studio/Studio"
import { useBays } from "./client"
import { BayPage } from "./sections/BayPage"
import { Landing } from "./sections/Landing"
import { EnvironmentSticker } from "../../../packages/web/components/EnvironmentSticker"

export function App() {
    const { data } = useBays()
    if (!data) return <></>

    return (
        <>
            <EnvironmentSticker />
            <ModalProvider>
                <LocalesProvider>
                    <Routes>
                        {Studio()}

                        <Route path="/" element={<Landing bays={data} />} />
                        {data.map((bay) => (
                            <Route
                                key={`legacy-slug-${bay.id.valueOf()}`}
                                path={bay.slug}
                                element={<BayPage bay={bay} />}
                            />
                        ))}
                        {data.map((bay) => (
                            <Route
                                key={`slug-${bay.id.valueOf()}`}
                                path={`/sites/${bay.siteId?.toString()}`}
                                element={<BayPage bay={bay} />}
                            />
                        ))}
                        {NotFound()}
                    </Routes>
                </LocalesProvider>
            </ModalProvider>
        </>
    )
}

function NotFound() {
    const error = { status: 404, type: "Not found", detail: "No page exists at this path" }
    return <Route path="*" element={<WebError error={error} />} />
}
