import { css } from "@emotion/react"
import { useEffect } from "react"
import { GetBaysDto, useBayPrograms } from "../client"
import { ProgramCard } from "./ProgramCard"

const bayPage = css`
    overflow: hidden;
    font-family: "Repro", tahoma;
    padding: 24px 48px;
    background: #2e3234;
    min-height: 100vh;

    @media screen and (min-width: 1020px) {
        height: 100vh;
    }
`

const header = css`
    color: #ffffff;
    font-size: 40px;
    font-weight: 350;
    margin-bottom: 32px;

    @media screen and (min-width: 520px) {
        font-size: 58px;
    }

    @media screen and (min-width: 1020px) {
        font-size: 88px;
    }
`

const programCards = css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;

    @media screen and (min-width: 520px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1020px) {
        grid-template-columns: repeat(4, 1fr);
    }
`

// Written to be previewed in 1080x690, and refreshed every minute. Responsiveness was added
// for the prices to be included in an iframe on their website.
export function BayPage({ bay }: { bay: GetBaysDto }) {
    const { data, refresh } = useBayPrograms(bay.slug)
    useEffect(() => {
        const interval = setInterval(refresh, 60_000)
        return () => clearInterval(interval)
    }, [refresh])
    if (!data) return <></>

    return (
        <div css={bayPage}>
            <div css={header}>Vaskeprogram</div>
            <div css={programCards}>
                {data.slice(0, 4).map((program, i) => (
                    <ProgramCard key={program.id.valueOf()} program={program} index={i} />
                ))}
            </div>
        </div>
    )
}
