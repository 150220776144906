import React, { createContext, useState } from "react"
import { createPortal } from "react-dom"

let setRoot: ((root: JSX.Element | undefined) => void) | undefined
let root: JSX.Element | undefined
let close: (result: any) => void

export type ModalContext = {
    /** Closes the current modal */
    close: (result: any) => void
}

/** If the context is defined, this means the current view is inside a modal.
 * The context object contains methods to control the modal. */
export const ModalContext = createContext<ModalContext | undefined>(undefined)

export function ModalProvider(props: { children: React.ReactNode }) {
    const state = useState<JSX.Element | undefined>(undefined)
    root = state[0]
    setRoot = state[1]

    return (
        <>
            {props.children}
            {root && (
                <ModalContext.Provider value={root ? { close } : undefined}>
                    <div
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                // Only trigger this if the user clicked outside of
                                // the modal, not inside the modal (got here by
                                // bubbling)
                                close(undefined)
                            } else if (
                                // Don't stop event propagation when clicking on normal links,
                                // otherwise they don't work (#1093)
                                Object.getPrototypeOf(e.target) !== HTMLAnchorElement.prototype
                            ) {
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        }}
                        style={{
                            backgroundColor: "#0008",
                            position: "fixed",
                            zIndex: 100,
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                maxHeight: "100%",
                                overflowY: "auto",
                            }}
                        >
                            {root}
                        </div>
                    </div>
                </ModalContext.Provider>
            )}
        </>
    )
}

/** Displays a JSX Element in a modal box.
 *
 *  The modal may be cancelled by clicking outside of it. In this case the
 *  promise will resolve to `undefined`.
 */
export function Modal<T>(render: (close: (result: T | undefined) => void) => JSX.Element) {
    const _setRoot = setRoot
    if (!_setRoot) throw new Error("Modal must be used inside a ModalProvider")

    return new Promise<T | undefined>((resolve) => {
        close = (result: T | undefined) => {
            _setRoot(undefined)
            resolve(result)
        }
        _setRoot(render(close))
    })
}

export type ContextModal<T> = (
    render: (close: (result: T) => void) => JSX.Element
) => Promise<T | undefined>

/** A modal that captures the React context of where it is placed.
 *
 *  This is useful if contents of the modal is dependent on context from where ti was spawned.
 */
export function useContextualModal<T>() {
    const [root, setRoot] = useState<JSX.Element | null>(null)
    const [portal, setPortal] = useState<HTMLDivElement | null>(null)
    const modal = root && portal && createPortal(root, portal)

    function Portal() {
        return (
            <div ref={(r) => setPortal(r)}>
                <div style={{ height: 0, width: 0 }} />
            </div>
        )
    }

    const showModal: ContextModal<T> = async (render) => {
        return await Modal<T>((close) => {
            setRoot(render(close))
            return <Portal />
        })
    }

    return {
        modal,
        showModal,
        showingModal: !!root,
    }
}

/**
 * Closes the current modal.
 */
export function closeContextualModal() {
    if (close) close(undefined)
}
