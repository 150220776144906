import { css } from "@emotion/react"
import { GetBayProgramsDto } from "../client"

const programCard = css`
    display: grid;
    grid-template-rows: auto auto 10fr 2fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "title"
        "price"
        "details"
        "duration";
    align-items: stretch;

    @media screen and (min-width: 520px) {
        grid-template-rows: 4fr 3fr 10fr 2fr;
    }

    @media screen and (min-width: 1020px) {
        grid-template-rows: 5fr 3fr 10fr 2fr;
    }
`

const programTitle = (index: number) => css`
    margin-bottom: 12px;
    @media screen and (min-width: 520px) {
        margin-bottom: 0;
    }
    grid-area: title;
    color: #f3f4f6;
    line-height: 1.32;
`
const programPrice = (index: number) => css`
    grid-area: price;
    color: #f3f4f6;
`
const programDetails = (index: number) => css`
    grid-area: details;
    color: #f3f4f6;
    border-top: 2px solid white;
    margin-top: 15px;
    padding-top: 20px;
`
const programDuration = (index: number) => css`
    grid-area: duration;
    color: #f3f4f6;
`
const font = (size: number, weight: number, strike?: boolean) => css`
    font-size: ${size * 0.8}px;
    font-weight: ${weight};
    text-decoration-line: ${strike ? "line-through" : "none"};

    @media screen and (min-width: 520px) {
        font-size: ${size * 0.9}px;
    }

    @media screen and (min-width: 1020px) {
        font-size: ${size}px;
    }
`
const programList = () => css`
    list-style-type: none;
    padding: 0px;
`

export function ProgramCard({ program, index }: { program: GetBayProgramsDto; index: number }) {
    const offerPrice = program.discountedPrice ?? program.price
    const oldPrice = program.discountedPrice ? program.price : undefined
    return (
        <div css={programCard}>
            <div css={programTitle(index)}>
                <div css={font(30, 500)} style={{ whiteSpace: "pre" }}>
                    {program.title.toUpperCase()}
                </div>
                <div css={font(18, 350)} style={{ whiteSpace: "pre" }}>
                    {program.subTitle?.toUpperCase()}
                </div>
            </div>

            <div css={programPrice(index)}>
                {oldPrice !== undefined ? (
                    <div css={font(18, 350, true)}>{formatPrice(oldPrice)}</div>
                ) : (
                    <div
                        css={css`
                            /** On mobile we only want the height required by the content. */
                            @media screen and (max-width: 519px) {
                                display: none;
                            }
                        `}
                    >
                        &nbsp;
                    </div>
                )}

                <div css={font(30, 700)}>{formatPrice(offerPrice)}</div>
            </div>

            <div css={programDetails(index)}>
                <ul css={programList}>
                    {program.treatments.map((t) => (
                        <li key={t.webText}>
                            <span key={t.webText} css={font(24, 350)}>
                                <Icon iconName={t.webIconName} />
                                {t.webText}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>

            <div css={programDuration(index)}>
                <div css={font(24, 350)}>{program.estimatedDurationMinutes} minutter</div>
            </div>
        </div>
    )
}
const formatPrice = (() => {
    const base = { style: "currency", currency: "NOK" } as const
    const fractionalFormat = new Intl.NumberFormat("nb-NO", {
        ...base,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
    const integerFormatter = new Intl.NumberFormat("nb-NO", {
        ...base,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })

    return (price: number) =>
        (Number.isInteger(price) ? integerFormatter : fractionalFormat).format(price)
})()

const Icon = ({ iconName }: { iconName: string }) => {
    switch (iconName) {
        case "2x":
            return <Icon2x />
        case "3x":
            return <Icon3x />
        case "brushless":
            return <IconBrushless />
        case "drying":
            return <IconDrying />
        case "underflushing":
            return <IconUnderflushing />
        default:
            return <Icon1x />
    }
}

const Icon1x = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={18}
        fill="none"
        css={() => `margin: 5px 15px 5px 0px; bottom: 3px; position: relative;`}
    >
        <path
            fill="#FFD4D1"
            d="M5.088 17.804V3.554L.123 7.224V4.73L5.28.892h2.015v16.912zm4.96 0 4.439-6.117-4.006-5.758h2.518l2.735 4.054 2.879-4.054h2.399l-4.054 5.566 4.438 6.309h-2.567l-3.19-4.678-3.095 4.678z"
        />
    </svg>
)
const Icon2x = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={18}
        fill="none"
        css={() => `margin: 5px 15px 5px 0px; bottom: 3px; position: relative;`}
    >
        <path
            fill="#FFD4D1"
            d="M6.762 10.007C8.393 8.064 9.09 6.913 9.09 5.377c0-1.583-.935-2.926-3.046-2.926-2.351 0-3.191 1.871-2.927 4.27H.86C.549 2.907 2.54.53 6.067.53c3.694 0 5.349 2.328 5.349 4.655 0 2.422-1.391 4.27-3.262 6.405l-3.67 4.198h7.244v2.015H.14zm6.606 7.797 4.438-6.117L13.8 5.929h2.519l2.735 4.054 2.878-4.054h2.4l-4.055 5.566 4.438 6.309h-2.567l-3.19-4.678-3.095 4.678z"
        />
    </svg>
)
const Icon3x = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={26}
        height={19}
        fill="none"
        css={() => `margin: 5px 15px 5px 0px; bottom: 3px; position: relative;`}
    >
        <path
            fill="#FFD4D1"
            d="M6.905 18.14c-4.126 0-6.093-2.76-5.95-5.734H3.26c-.048 2.327 1.32 3.79 3.646 3.79 2.135 0 3.646-1.271 3.646-3.046 0-1.583-1.223-3.023-3.862-3.023H5.658V8.112h1.031c2.28 0 3.335-1.295 3.335-2.902 0-1.536-1.248-2.735-3.215-2.735-1.823 0-3.262 1.055-3.31 3.43h-2.28C1.245 2.74 3.404.555 6.81.555c3.503 0 5.518 2.112 5.518 4.487 0 1.847-1.128 3.382-2.807 3.934 1.871.6 3.358 2.11 3.358 4.27 0 2.758-2.422 4.894-5.973 4.894m7.71-.336 4.437-6.117-4.006-5.758h2.52L20.3 9.983l2.879-4.054h2.399l-4.055 5.566 4.438 6.309h-2.566l-3.191-4.678-3.095 4.678z"
        />
    </svg>
)
const IconBrushless = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        css={() => `margin: 5px 15px 5px 0px; bottom: 3px; position: relative;`}
    >
        <g clipPath="url(#prefix__a)">
            <path
                fill="#545657"
                d="M4.65 2.803a.5.5 0 0 1 .91 0l4.198 9.17a.5.5 0 0 1-.454.707H.906a.5.5 0 0 1-.454-.707zM14.146 2.803a.5.5 0 0 1 .909 0l4.199 9.17a.5.5 0 0 1-.455.707h-8.397a.5.5 0 0 1-.455-.707z"
            />
            <path stroke="#FFD4D1" strokeWidth={2.999} d="M.857 2.81h16.014l5.476 4.046V24.8" />
        </g>
        <defs>
            <clipPath id="prefix__a">
                <path fill="#fff" d="M.357.811h23.99V24.8H.356z" />
            </clipPath>
        </defs>
    </svg>
)
const IconDrying = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        css={() => `margin: 5px 15px 5px 0px; bottom: 3px; position: relative;`}
    >
        <g clipPath="url(#prefix__a)">
            <path
                fill="#FFD4D1"
                d="M10.852 17.8H4.355v-2h6.497a3.498 3.498 0 1 1-3.276 4.728l1.872-.702a1.5 1.5 0 1 0 1.404-2.027m-5.497-5.998h13.494a3.498 3.498 0 1 1-3.277 4.728l1.872-.703a1.499 1.499 0 1 0 1.405-2.026H5.355a2.999 2.999 0 1 1 0-5.997h8.496a1.5 1.5 0 1 0-1.404-2.026l-1.872-.702a3.499 3.499 0 1 1 5.75 3.703 3.5 3.5 0 0 1-2.474 1.024H5.355a1 1 0 0 0 0 2"
            />
        </g>
        <defs>
            <clipPath id="prefix__a">
                <path fill="#fff" d="M.357.807h23.99v23.99H.356z" />
            </clipPath>
        </defs>
    </svg>
)
const IconUnderflushing = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        css={() => `margin: 5px 15px 5px 0px; bottom: 3px; position: relative;`}
    >
        <path
            fill="#FFD4D1"
            d="M11.852 8.802H7.855l-.001 11.994h-2V8.802H1.857l4.998-4.998zM22.847 8.802h-3.998v11.994h-2V8.802h-3.998l4.998-4.998z"
        />
    </svg>
)
